import React from "react";

import Banner from "./../Components/Banner";

const Home = () => {
  return (
    <div className="min-h-[1800px]">
      <Banner />
    </div>
  );
};

export default Home;
