import React from "react";

const Footer = () => {
  return (
    <div className="bg-black py-4 text-center text-white  mt-20">
      <footer className="w-full">
        Copyright &copy; 2024. All rights reserved.
      </footer>
    </div>
  );
};

export default Footer;
